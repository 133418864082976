<template>
  <v-container class="background" fluid :style="'background-image: url('+require('@/assets/images/titlePicture.jpg')+')'">
    <v-row v-if="this.alert === false" >
      <v-col cols="12" class="d-flex justify-center align-center" style="height: 100vh">
        <v-card class="pa-9 transparent-card rounded-xl">
          <h3 class="pb-5 text-left">Passwort zurücksetzen</h3>
          <p class="text-left">Um dein Passwort zurückzusetzen, gebe hier deine E-Mail an!</p>
          <v-text-field label="E-mail" v-model="email" :rules="[rules.required, rules.email]" class="pb-5"></v-text-field>
          <v-btn block color="primary" @click="resetPassword">Passwort zurücksetzen</v-btn>
          <div class="text-center pt-3">
            <a @click="$router.push('/login')">Zurück zur Anmeldung</a>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="d-flex justify-center align-center" style="height: 100vh">
        <v-card class="pa-9 transparent-card rounded-xl">
          <p class="text-left">Email verschickt!</p>
          <a @click="$router.push('/login')">Zurück zur Anmeldung</a>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      alert: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ungültige E-Mail Adresse'
        },
      }
    }
  },
  methods: {
    resetPassword() {
      if (this.email) {
        this.$store.dispatch('auth/forgotPassword', {
          email: this.email
        }).then(()=>{
          this.alert = true;
          const timer = setInterval(()=> this.alert = true, 1000)
          setTimeout(() => { clearInterval(timer);this.alert = false; }, 5000);
        })

      }
    },
  }
}
</script>

<style scoped>
.transparent-card{
  backdrop-filter: blur(9px) !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
.background{
  height: 102vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
}
</style>
